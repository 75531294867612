.searchBox{
    margin: 10px;
    padding: 5px;
    background-color: transparent;
    border: 1px solid #3C4752;
    border-radius: 3px;
    font-size: 14px;
    color: white;
    &:focus-visible{
        outline:none;
    }
    &::placeholder{
       color: #3C4752;
    }
    &::-webkit-search-cancel-button {
        -webkit-appearance: none;

            height: 10px;
            width: 10px;
            background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE2LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgd2lkdGg9IjEyMy4wNXB4IiBoZWlnaHQ9IjEyMy4wNXB4IiB2aWV3Qm94PSIwIDAgMTIzLjA1IDEyMy4wNSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTIzLjA1IDEyMy4wNTsiDQoJIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGc+DQoJPHBhdGggZD0iTTEyMS4zMjUsMTAuOTI1bC04LjUtOC4zOTljLTIuMy0yLjMtNi4xLTIuMy04LjUsMGwtNDIuNCw0Mi4zOTlMMTguNzI2LDEuNzI2Yy0yLjMwMS0yLjMwMS02LjEwMS0yLjMwMS04LjUsMGwtOC41LDguNQ0KCQljLTIuMzAxLDIuMy0yLjMwMSw2LjEsMCw4LjVsNDMuMSw0My4xbC00Mi4zLDQyLjVjLTIuMywyLjMtMi4zLDYuMSwwLDguNWw4LjUsOC41YzIuMywyLjMsNi4xLDIuMyw4LjUsMGw0Mi4zOTktNDIuNGw0Mi40LDQyLjQNCgkJYzIuMywyLjMsNi4xLDIuMyw4LjUsMGw4LjUtOC41YzIuMy0yLjMsMi4zLTYuMSwwLTguNWwtNDIuNS00Mi40bDQyLjQtNDIuMzk5QzEyMy42MjUsMTcuMTI1LDEyMy42MjUsMTMuMzI1LDEyMS4zMjUsMTAuOTI1eiIvPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPC9zdmc+DQo=);
            background-size: 10px 10px;
            

    }
    
}

#search-results-box {
    position: absolute;
    background-color: #222b36;
    color: white;
    width: 400px;
    left: 270px;
    top: 0px;
    z-index: 1000 !important;
    border-bottom-style: solid;
    border-right-style: solid;
    border-left-style: solid;
    border-color: #3C4752;
    border-width: 1px;
}

.search-link {
    margin: 15px 0;
    padding: 5px 15px;
    background-color: #3C4752;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #3C4752;
    &:hover {
        background-color: #222b36;
        border: 1px solid #3C4752;
        & .task-id{
            color: #3C4752;

        }
    }
}
.task-id {
    display: block;
    font-weight: bold;
    color: #222b36;
}

@media(max-width: 769px){
    .searchBox{
        margin-left: -80px;
        width: 80px;
    }
    #search-results-box {
        position: absolute;
        background-color: #222b36;
        color: white;
        width: 100%;
        left: 0px;
        top: 50px;
        z-index: 1000 !important;
        border-bottom-style: solid;
        border-right-style: none;
        border-left-style: hidden;
        border-color: #3C4752;
        border-width: 1px;
    }


}

