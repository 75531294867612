.sidebar {
    height: 100vh !important;
    margin-top: 50px;
    border-top-style: solid;
    border-right-style: solid;
    border-color: #3C4752;
    border-width: 1px;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content{
    white-space: inherit !important;
}
.pro-sidebar .pro-menu .pro-menu-item{
    padding-bottom: 10px;
}