$sidebar-bg-color: #222B36 !default;
$icon-bg-color: #222B36 !default;
$submenu-bg-color: #222B36 !default;
@import '~react-pro-sidebar/dist/scss/styles.scss';



::-webkit-scrollbar{
  width: 10px;
}
::-webkit-scrollbar-track{
  background-color: #222b36;
}
::-webkit-scrollbar-thumb{
  background-color: #3C4752;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  background: #ff7b00;
}

.content-wrapper{
  padding-top: 50px;
  margin-left: 270px;
}

body {
  text-align: left;
  background-color: #171c24;
  color: white;
  margin: 0;
  padding-bottom: 50px;
}
::selection {
  background: #463030; /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: #463030; /* Gecko Browsers */
}

.active {
  color: rgb(255, 115, 0) !important;
}

.nav {
  font-size: 50px;
}

.sidebar {
  height: 100vh !important;
  float: left;
  position: fixed;
  margin-top: 50px;
  border-top-style: solid;
  border-right-style: solid;
  border-color: #3C4752;
  border-width: 1px;
}
h2{
  padding-left: 60px;
}
p{
  padding-right: 60px;
}
.keynotes{
  float: left;
  margin-left: 80px;
  width: 100%;
  margin-top: -10px;
  line-height: 43px;
}
.key{
  width: 40px;
  margin-left: -100px;
  margin-top: 5px;
  position: absolute;
}
.gifnotes{
  margin-left: 0px;
  width: 100%;
  line-height: 43px;
}
.finalnotes{
  padding-bottom: 40px;
  padding-top: 20px;
  margin-left: 0px;
  width: 100%;
  margin-top: -10px;
  line-height: 43px;
}
.hc{
  width: 30px;
  margin-left: -100px;
  margin-top: 5px;
  position: absolute;
}
#tutorial{
  padding-top: 40px;
}



@media(max-width: 769px){
.content-wrapper{
  margin-left: 0px;
  width: 100vw;
  overflow-x: hidden;

  .keynotes{
    float: left;
    margin-left: 30px;
    width: 100%;
    margin-top: -10px;
    line-height: 43px;
  }
  .key{
    width: 40px;
    margin-left: -70px;
  }
  .hc{
    width: 30px;
    margin-left: -70px;
  }
  .gifdemo{
    width: 400vw;
    display: none;
  }
  .gifnotes{
    margin-left: 0px;
    width: 100vw;
    line-height: 43px;
    display: none;
  }
  .finalnotes{
    padding-bottom: 40px;
    padding-top: 20px;
    margin-left: 0px;
    width: 100%;
    margin-top: -10px;
    line-height: 43px;
  }

  #tutorial{
    padding-top: 40px;
  }
}
}