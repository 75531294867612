.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: fixed;
  top: 0;
  width: 100vw;
  background-color: #222b36;
  height: 50px;
  border-bottom-style: solid;
  border-color: #3c4752;
  border-width: 1px;
  z-index: 10;
  & img {
    height: 30px;
    width: auto;
    margin-left: 20px;
  }
}

.display-name {
  margin: 15px 15px;
  font-size: 25px;
  font-family: "Runescape Chat Font Regular";
}
.namebox{
  background-color: transparent;
  color: white;
  margin: 10px;
  padding: 5px;
  background-color: transparent;
  border: 1px solid #3C4752;
  border-radius: 3px;
  font-size: 14px;
}
.savebutton{
  background-color: transparent;
      margin: 10px;
    padding: 5px;
    background-color: transparent;
    border: 1px solid #3C4752;
    border-radius: 3px;
    font-size: 14px;
    color: #3C4752;
    &:hover {
      color: white;
      border: 1px solid white;
    }
}
.w-100 {
  width: 100%;
}


.app {
  height: 50px;
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ref-button {
  width: 30px;
  height: 30px;
  background-color: #ff8800;
  border: 3px solid #3c4752;
  cursor: pointer;
  margin-left: 10px;
}
.sketch-picker {
  background: #3c4752 !important;
}
label[for^=rc-editable-input] {
  color: white !important;
}


@media(max-width: 769px){
  .display-name {
    font-size: 20px;
    font-family: "Runescape Chat Font Regular";
  }
}