h3 {
    font-weight: bolder;
    margin-left: 40px;
    margin-right: 40px;
    padding-bottom: 20px;
    border-bottom: solid;
    border-width: 1px;
    border-color: #3C4752;
}
h2 {
    padding-left: 30px;
}
.task {
    padding-left: 80px;
    line-height: 30px;
    padding-right: 30px;
}
.video{
    margin-left: 0%;
    margin-top: 20px;
    margin-bottom: 50px;
    height: 360px;
    width: 640px;
}
.h-popover {
    background-color: #3C4752;
    &::after {
        border-top: 6px solid #3C4752;
    }
    img {
        width: 75px;
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 0px;
        margin-top: 0px;
    }    
}
.color{
    height: 100px;
    width: 100px;

}

.gifdemo{
    height: auto;
    width: 50%;
    position: relative;
    max-width: 50%;
}
.oziris{
    color: white;
    &:hover {
        color:#774c4c;
    }
}





.idtext{
    height: 20px;
    width: 20px;
    margin-left: -70px;
    display: block;
    padding-top: 0;
    position: absolute;
}
    .id{
    color:#3C4752;
    display: block;
    text-align: right;
    padding-top: 0px;
}




/* The container */
.container {
    display: block;
    position: relative;
    padding-left: 0px;
    margin-bottom: 12px;
    cursor: pointer;
  }
  .steps {
    display: block;
    position: relative;
    padding-left: 0px;
    margin-bottom: 12px;
    cursor: pointer;
  }
  
  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  





img{
    width: 50%;
    padding-left: 2%;
    padding-right: 5%;
    margin-bottom: 20px;
    margin-top: 10px;
    float: right;
}


.notes {
    color: #FFFF00;
    padding-left: 130px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 90px;
    font-family: "Runescape Chat Font Regular";
    letter-spacing: 1px;
    font-size: 18px;
    img {
        display: inline;
        width: 30px;
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 0;
        margin-right: 30px;
        margin-top: 0px;
        float: none;
    }
    span {
        line-height: 33px;
        vertical-align: top;
    }
}


.inventory {
    color: rgb(255, 123, 0);
    letter-spacing: 1px;
    font-size: 18px;
    padding-left: 130px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: "Runescape Chat Font Regular";
    img {
        display: inline;
        width: 30px;
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 0;
        margin-right: 30px;
        margin-top: 0px;
        float: none;
    }
    h4 {
        display: inline;
        font-weight: 800;
    }
    span {
        line-height: 33px;
        vertical-align: top;
    }
}
.equipment {
    color: #cc00ff;
    letter-spacing: 1px;
    font-size: 18px;
    padding-left: 130px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: "Runescape Chat Font Regular";
    img {
        display: inline;
        width: 30px;
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 0;
        margin-right: 30px;
        margin-top: 0px;
        float: none;
    }
    h4 {
        display: inline;
        font-weight: 800;
    }
    span {
        line-height: 33px;
        vertical-align: top;
    }
}
.hardcore{
    color: #ac1b1b;
    letter-spacing: 1px;
    font-size: 18px;
    padding-left: 130px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: "Runescape Chat Font Regular";
    img {
        display: inline;
        width: 25px;
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 0;
        margin-right: 30px;
        margin-top: 0px;
        float: none;
    }
    span {
        line-height: 33px;
        vertical-align: top;
    }
}

p{
    padding-left: 60px;
}
.screenshot{
    float: right;
}



@media(max-width: 769px){
    h2{
        margin-left: -20px;
    }
    img{
        float: left;
        width: 85%;
        margin-bottom: 20px;
        margin-top: 10px;
        padding-right: 100px;
    }
    .task {
        line-height: 30px;
        width: 75vw;
    }
    .video{
        margin-left: -60px;
        padding-left: 0px;
        width: 90vw;
        height: auto;
    }
    .hardcore{
        color: #ac1b1b;
        width: 75vw;
        padding-left: 0px;
        margin-left: -20px;
        margin-top: 20px;
        margin-bottom: 20px;
        margin-right: 10px;
        font-family: "Runescape Chat Font Regular";
        letter-spacing: 1px;
        font-size: 18px;
        img {
            display: inline;
            width: 25px;
            padding-left: 0;
            padding-right: 0;
            margin-bottom: 0;
            margin-right: 30px;
            margin-top: 0px;
            float: none;
        }
        span {
            line-height: 53px;
            vertical-align: top;
        }
    }
    .notes {
        color: #FFFF00;
        width: 75vw;
        padding-left: 0px;
        margin-left: -20px;
        margin-top: 20px;
        margin-bottom: 20px;
        margin-right: 10px;
        font-family: "Runescape Chat Font Regular";
        letter-spacing: 1px;
        font-size: 18px;
        img {
            display: inline;
            width: 20px;
            padding-left: 0;
            padding-right: 0;
            margin-bottom: 0;
            margin-right: 30px;
            margin-top: 0px;
            float: none;
        }
        span {
            line-height: 33px;
            vertical-align: top;
        }
    }
    
    
    .inventory {
        color: rgb(255, 123, 0);
        width: 75vw;
        letter-spacing: 1px;
        font-size: 18px;
        margin-left: -20px;
        padding-left: 0px;
        margin-top: 20px;
        margin-bottom: 20px;
        margin-right: 10px;
        font-family: "Runescape Chat Font Regular";
        img {
            display: inline;
            width: 30px;
            padding-left: 0;
            padding-right: 0;
            margin-bottom: 0;
            margin-right: 30px;
            margin-top: 0px;
            float: none;
        }
        h4 {
            display: inline;
            font-weight: 800;
        }
        span {
            line-height: 33px;
            vertical-align: top;
        }
    }
    .equipment {
        color: #cc00ff;
        letter-spacing: 1px;
        font-size: 18px;
        padding-left: 60px;
        margin-top: 20px;
        margin-bottom: 20px;
        font-family: "Runescape Chat Font Regular";
        img {
            display: inline;
            width: 30px;
            padding-left: 0;
            padding-right: 0;
            margin-bottom: 0;
            margin-right: 30px;
            margin-top: 0px;
            float: none;
        }
        h4 {
            display: inline;
            font-weight: 800;
        }
        span {
            line-height: 33px;
            vertical-align: top;
        }
    }

}

