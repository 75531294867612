
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 3px;
    left: -40px;
    height: 25px;
    width: 25px;
    background-color: #3C4752;
    border-radius: 5px;
  }
  .steps{
    pointer-events: none;
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: #222B36;
  }

  .container input:checked ~ .steps {
    text-decoration: line-through;
    color: #3C4752;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after{
    display: block;
  }
  